import { Link } from "gatsby"
import React from "react"
import { ArrowLeft } from "../Icons/ArrowLeft"

export type HeaderSubPageProps = {
  title?: string
  isStatic?: boolean
  to?: string
}

export const HeaderSubPage: React.FC<HeaderSubPageProps> = ({
  title,
  isStatic,
  to,
}) => (
  <nav className={`w-full ${!isStatic && "fixed"} top-0 bg-white border`}>
    <div className="container mx-auto px-6 flex items-center h-14">
      {to && (
        <>
          <Link
            to={to}
            className="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
          >
            <ArrowLeft />
          </Link>
          <span className="text-gray-500 text-xl pl-4">{title}</span>
        </>
      )}
    </div>
  </nav>
)
