import React from "react"
import { HeaderSubPage } from "../components/HeaderSubPage/HeaderSubPage"
import { Seo } from "../components/Seo/Seo"
import { PageProps } from "gatsby"

export default ({ location }: PageProps) => {
  return (
    <>
      <Seo
        index={false}
        title="Comment écrire un haiku ?"
        description="Ecrire un haïku semble simple mais demande de respecter plusieurs règles essentielles"
      />

      <div className="min-h-screen bg-gray-50">
        <HeaderSubPage
          title="Aide"
          to={location?.state?.from || "/"}
        />
        <HeaderSubPage isStatic />

        <div>
          <div className="max-w-screen-xl px-4 py-16 mx-auto sm:px-6 lg:py-20 lg:px-8">
            <div className="lg:grid lg:grid-cols-3 lg:gap-8">
              <div className="space-y-4">
                <h2 className="text-3xl font-extrabold leading-9 text-gray-900">
                  Questions fréquentes
                </h2>
                <p className="text-lg leading-7 text-gray-500">
                  N'hésitez pas à me faire des retours directement si vous avez
                  des questions que cette page ne répond pas.
                </p>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-2">
                <dl className="space-y-12">
                  <div className="space-y-2">
                    <dt className="text-lg font-medium leading-6 text-gray-900">
                      Comment écrire un haiku ?
                    </dt>
                    <dd className="text-base leading-6 text-gray-500">
                      La règle est assez simple, il existe beaucoup de règles à
                      respecter mais pour le moment il suffit simplement
                      d'écrire trois lignes. Plus tard, l'éditeur de haïku sera
                      amélioré et vous guidera de manière plus intélligente.
                    </dd>
                  </div>
                  <div className="space-y-2">
                    <dt className="text-lg font-medium leading-6 text-gray-900">
                      À quel moment je peux savoir que le haïku est bon ?
                    </dt>
                    <dd className="text-base leading-6 text-gray-500">
                      La petite icône en bas à droite dans la page d'édition a 3
                      différents états.
                      <br />
                      Lorsque l'indicateur est vert, alors vous pouvez publier.
                      <br />
                      Lorsque l'indicateur est rouge, il est impossible de
                      publier.
                      <br />
                      Lorsque l'indicateur est gris, on comprend que vous n'avez
                      pas encore terminer d'écrire le haïku.
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
